// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../Assets/Images/PublicatonHeadeImage2.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublicationList .headerBox {\r\n    display: flex;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-size: cover;\r\n    background-position: center;\r\n    margin-top: 9vh;\r\n    height: 50vh;\r\n}\r\n\r\n.PublicationList .headerTitle {\r\n    width: 100vh;\r\n    background-color: rgba(255,255,255,0.5);\r\n}\r\n\r\n.PublicationList .headerTitle p {\r\n    font-size: 3.2rem;\r\n}\r\n@media (max-width: 850px) {\r\n    .PublicationList .headerTitle p {\r\n        font-size: 2.2rem;\r\n    }\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Components/DataArea/PublicationsArea/PublicationList/PublicationList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yDAA6E;IAC7E,sBAAsB;IACtB,2BAA2B;IAC3B,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,uCAAuC;AAC3C;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".PublicationList .headerBox {\r\n    display: flex;\r\n    background-image: url('../../../../Assets/Images/PublicatonHeadeImage2.jpeg');\r\n    background-size: cover;\r\n    background-position: center;\r\n    margin-top: 9vh;\r\n    height: 50vh;\r\n}\r\n\r\n.PublicationList .headerTitle {\r\n    width: 100vh;\r\n    background-color: rgba(255,255,255,0.5);\r\n}\r\n\r\n.PublicationList .headerTitle p {\r\n    font-size: 3.2rem;\r\n}\r\n@media (max-width: 850px) {\r\n    .PublicationList .headerTitle p {\r\n        font-size: 2.2rem;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
