// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../Assets/Images/BrainShowcase.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Home {\n    overflow: auto;\n}\n\n.Home .headerBox {\n    display: flex;\n    height: 90vh;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-size: cover;\n    background-position: center;\n    font-size: large;\n    color: white;\n}\n\n.Home .headerTitle p {\n    font-size: 3.2rem;\n}\n@media (max-width: 850px) {\n    .Home .headerTitle p {\n        font-size: 2.2rem;\n    }\n}\n\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://./src/Components/HomeArea/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,yDAAiE;IACjE,sBAAsB;IACtB,2BAA2B;IAC3B,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".Home {\n    overflow: auto;\n}\n\n.Home .headerBox {\n    display: flex;\n    height: 90vh;\n    background-image: url('../../../Assets/Images/BrainShowcase.jpg');\n    background-size: cover;\n    background-position: center;\n    font-size: large;\n    color: white;\n}\n\n.Home .headerTitle p {\n    font-size: 3.2rem;\n}\n@media (max-width: 850px) {\n    .Home .headerTitle p {\n        font-size: 2.2rem;\n    }\n}\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
